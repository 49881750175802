@import "styles/variables";

.home {
  &__events-container-loader {
    display: flex;
  }

  &__events-container-loader-content {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  &__events-container-loader-content h2 {
    padding-left: 0.625rem;
  }

  &__events-list {
    padding: 1.25rem 0;
    display: inline-block;
  }
}
