@import "styles/variables";

.seeds {
  margin: 0;

  &__add-rider-button-container {
    padding-right: 0;
  }

  &__button-group-class {
    @media only screen and (max-width: 768px) {
      padding-right: 0.5rem !important;
    }
    @media only screen and (max-width: 576px) {
      padding-right: 1rem !important;
    }
  }

  &__category {
    font-family: sans-serif;
    padding: 0.625rem 0.3rem 0.125rem 0.3rem;
    opacity: 1;
    font-weight: 600;
    color: $grey-text;
  }

  &__category-label-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__category-label-primary {
    font-size: 1.2rem;
  }

  &__category-secondary {
    font-size: 0.875rem;
    display: flex;
    align-items: flex-end;
    @media only screen and (max-width: 768px) {
      padding-right: 0.5rem !important;
    }
    @media only screen and (max-width: 576px) {
      padding-right: 1rem !important;
    }
    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  &__confidence-header {
    @media screen and (max-width: 768px) {
      padding-left: 1.5rem;
    }
  }

  &__drag-handle {
    color: $grey-light;
    display: flex;
    align-items: center;
  }

  &__header {
    border-bottom: 0.02rem solid $grey-border;
    padding-bottom: 1rem;
  }

  &__header-buttons {
    display: flex;
    margin-left: auto !important;
    @media only screen and (max-width: 576px) {
      padding-top: 0.5rem;
    }
  }

  &__link {
    color: $ncim-blue-link;
    text-decoration: none !important;
    &:hover {
      color: $ncim-blue-active;
    }
  }

  &__name {
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-text;
  }

  &__rider {
    font-family: sans-serif;
    background-color: $ncim-white;
    margin-bottom: 0.25rem;
    padding: 0.625rem 0 0.625rem 0;
    opacity: 1;
    border-radius: 0.625rem;
    border: 0.06rem solid $grey-border;
    color: $grey-text;
    vertical-align: center;
    @media only screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  &__update-starting-lineups {
    padding: 1rem 0;
  }
}
