@import "styles/variables";

.riders {
  margin: 0;

  &__arrow-icon {
    padding-right: 0.75rem;
  }

  &__category-secondary {
    font-size: 0.875rem;
    font-weight: 100;
    text-align: right;
    vertical-align: middle;
    color: $grey-text;
    display: flex;
    justify-content: flex-end;
  }

  &__drag-handle {
    color: $grey-light;
    display: flex;
    align-items: center;
  }

  &__edit-category-prompt {
    margin-top: 2rem;
    padding: 1rem 0;
    background-color: $grey-active;
    border: solid 0.01rem $grey-border;
    border-radius: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $grey-text;
  }

  &__edit-category-prompt-button {
    margin-right: 1rem;
  }

  &__edit-category-prompt-text {
    margin-left: 1rem;
  }

  &__edit-category-prompt {
    padding: 1rem 0;
    background-color: $grey-active;
    border: solid 0.01rem $grey-border;
    border-radius: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__edit-category-prompt-button {
    margin-right: 1rem;
  }

  &__edit-category-prompt-text {
    margin-left: 1rem;
  }

  &__export {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      padding-right: 0;
    }
  }

  &__go-back {
    padding-bottom: 1rem;
    color: $ncim-blue-link;
    text-decoration: none !important;
    &:hover {
      color: $ncim-blue-link;
    }
  }

  &__header-row {
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-weight: bold;
    flex-wrap: nowrap;
    @media screen and (max-width: 400px) {
      font-size: 0.8rem;
    }
  }

  &__header-row-item {
    color: $grey-text;
    font-weight: bold;
  }

  &__header-row-item-placing {
    padding-left: 1rem !important;
  }

  &__header-row-item-dragging {
    color: $grey-text;
    font-weight: bold;
    word-wrap: normal;
  }

  &__heat-name {
    font-size: 1.35rem;
    font-weight: 600;
    color: $grey-text;
    word-wrap: normal;
  }

  &__heat-warning {
    color: $invalid-color;
    font-weight: normal;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  &__info-per-category {
    color: $grey-text;
    padding-top: 1rem;
  }

  &__notes {
    display: flex;
    justify-content: center;
  }

  &__placing {
    display: flex;
    font-weight: bold;
    justify-content: center;
  }

  &__placing-header {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__race-details-button {
    display: flex;
  }

  &__race-header {
    padding: 1rem 0;
    border-bottom: 0.01rem solid $grey-border;
    @media screen and (max-width: 768px) {
      padding: 1rem 0;
    }
  }

  &__race-category {
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-text;
    word-wrap: normal;
  }

  &__race-type-name {
    font-weight: 400;
  }

  &__rider {
    font-family: sans-serif;
    background-color: $ncim-white;
    padding: 0.5rem 0.25rem;
    opacity: 1;
    border-radius: 0.75rem;
    border: 0.01rem solid $grey-border;
    color: $grey-text;
  }

  &__rider-no-dragging {
    cursor: default;
  }

  &__row {
    flex-wrap: nowrap;
  }

  &__subcategory {
    font-family: sans-serif;
    margin-bottom: 0 !important; //overwrite css from drag and drop lib
    padding: 2rem 0 0 0;
    opacity: 1;
    font-weight: bold;
    vertical-align: middle;
    color: $grey-text;
  }

  &__subcategory-buttons {
    @media screen and (max-width: 768px) {
      text-align: right;
      padding: 0.5rem 1rem;
    }
  }

  &__top-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding-top: 1rem;
      justify-content: flex-start;
    }
  }
}

p {
  margin: 0;
}
