@import "styles/variables";

.buttonGroup {
  margin: 0;
  display: flex;

  &__alert-info {
    font-size: 0.75rem;
    padding: 0.2rem 0 0.2rem 0;
    margin: -0.5rem 0 -0.25rem 0;
    background-color: $ncim-white;
    color: $ncim-blue;
    border: 0;
  }

  &__bib {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__bib-feedback {
    padding-left: 0;
  }

  &__bib.form-control.is-invalid {
    background-image: $danger-symbol;
    min-width: 5rem;
    @media screen and (max-width: 576px) {
      min-width: 3.5rem;
    }
    @media screen and (max-width: 400px) {
      background-image: none;
      min-width: 2.5rem;
      padding-right: 0.25rem;
    }
  }

  &__bib-input {
    display: flex;
    width: 4rem;
    text-align: right;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 576px) {
      width: 3rem;
    }
    @media only screen and (max-width: 400px) {
      font-size: 0.8rem;
      width: 2.5rem;
    }
  }

  &__button-group {
    width: 100%;
    padding: 0;
  }

  &__drag-handle {
    color: $grey-light;
    display: flex;
    align-items: center;
  }

  &__dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__name {
    display: flex;
    align-items: center;
  }

  &__ranking {
    color: $grey-text;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__riders-name {
    @media only screen and (max-width: 300px) {
      padding-left: 0.5rem;
    }
  }

  &__stdev {
    color: $grey-text;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dropdown-toggle.btn.btn-light {
  padding-top: 0;
  padding-bottom: 0;
  color: darkgrey;
  background-color: transparent;
  border-color: transparent;
}
