@import "styles/variables";

.app-bar {
  background-color: $ncim-white;
  box-shadow: 0 0.1rem 0.2rem 0.1rem $box-shadow-color;
  padding: 0.8rem;

  &__event-data {
    @media screen and (max-width: 556px) {
      padding-top: 1rem;
    }
  }

  &__event-details {
    font-size: 1.15rem;
    font-weight: bold;
    color: $grey-text;

    span {
      font-weight: 300;
      font-size: 0.9rem;
      margin-left: 1.4rem;
    }

    .link {
      cursor: pointer;
    }

    .date {
      color: $grey-text-light;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  &__ncim-logo {
    height: 2.75rem;
    float: left;
    padding-left: 0.5rem;
  }
}
