@import "styles/variables";

.upload-file {
  &__download-link {
    color: $grey-text;
    text-decoration: underline;
  }

  &__download-link:hover {
    color: $ncim-blue;
    text-decoration: underline;
  }

  &__input-text {
    p {
      padding-top: 0.5rem;
    }
  }

  &__upload {
    color: $ncim-blue;
    background-color: $ncim-white;
    border: 0.1rem solid $grey-border;
  }
}
