@import "styles/variables";

.event-setup {
  display: flex;

  &__datetime {
    width: 10rem;
  }

  &__datefields-row {
    padding: 0 0 1rem 1rem;
  }

  &__delete-event-button {
    display: flex;
  }

  &__divider {
    border-top: 0.1rem solid $grey-border;
  }

  &__dropdown-items {
    color: $grey-text;
  }

  &__endtime-text {
    padding-right: 0.75rem;
  }

  &__event-buttons {
    display: flex;
    justify-content: right;
  }

  &__fields {
    align-items: center;
    padding: 0.5rem 0;
    color: $grey-text;
    @media screen and (max-width: 768px) {
      padding: 0.5rem;
    }
  }

  &__fields-text {
    color: $grey-text;
    align-self: center;
  }

  &__header {
    padding: 1rem 0;
  }

  &__race-name-input {
    padding: 0 0.25rem;
    height: 1.85rem;
    width: 11rem;
    border-color: $grey-border;
  }

  &__race-name-input:focus {
    border-color: $ncim-blue-hover-border !important;
    box-shadow: 0 0 0.25rem 0.25rem $ncim-blue-focus-border-shadow !important;
  }

  &__race-type-chevron {
    height: 1.85rem;
    color: $grey-text;
    background-color: $ncim-white;
    border-color: $grey-border;
  }

  &__race-type-chevron:hover {
    color: $grey-text !important;
    background-color: $grey-hover !important;
    border-color: $ncim-blue-hover-border !important;
  }

  &__race-type-chevron:focus {
    color: $grey-text !important;
    background-color: $grey-hover !important;
    border-color: $ncim-blue-hover-border !important;
    box-shadow: 0 0 0.25rem 0.25rem $ncim-blue-focus-border-shadow !important;
  }

  &__race-type-chevron:active {
    color: $grey-text !important;
    background-color: $grey-active !important;
    border-color: $ncim-blue-active-border !important;
    box-shadow: none !important;
  }

  &__race-type-input {
    padding: 0 0.25rem;
    height: 1.85rem;
    width: 10rem;
    text-align: left;
    color: $grey-text;
    background-color: $ncim-white;
    border-color: $grey-border;
    cursor: default !important;
  }

  &__race-type-input:hover,
  &__race-type-input:active,
  &__race-type-input:focus {
    color: $grey-text !important;
    background-color: $ncim-white !important;
    border-color: $grey-border !important;
    box-shadow: none !important;
  }

  &__riders-added-text {
    color: $ncim-blue;
  }

  &__starttime-text {
    padding-right: 0.45rem;
  }

  &__starttime-box {
    padding-right: 2rem;
  }

  &__table {
    color: $grey-text;
    vertical-align: center !important;
    align-self: center;
    align-items: center;
  }

  &__table-row:nth-child(4n + 1),
  &__table-row:nth-child(4n + 2) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__table-td {
    padding-left: 2rem !important;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-text;
    margin: 0;
    @media screen and (max-width: 768px) {
      padding: 0.5rem;
    }
  }

  &__warning-text {
    text-align: center;
    padding-top: 0 !important;
  }
}
