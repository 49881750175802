@import "styles/variables";

.add-category {
  color: $grey-text-light;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  padding: 1rem;
  margin-right: 1rem;

  &__text {
    font-weight: 600;
  }
}
