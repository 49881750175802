@import "styles/variables";

.delete-category {
  position: relative;
  font-size: 0.875rem;
  font-weight: normal;
  color: $grey-text;
  text-align: center;
  padding: 0.625rem;

  &__delete {
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }
}
