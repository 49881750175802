@import "styles/variables";

.addRider-details {
  &__fields {
    margin-bottom: 0.625rem;
    color: $grey-text;
  }

  &__input-boxes {
    margin-left: 0;
    margin-right: 0;
    height: 1.85rem;
  }

  &__input-description {
    float: left;
    margin-left: 0;
    margin-right: 0;
    padding-right: 0;
  }
}
