@import "styles/variables";

.update-starting-lineups {
  border-radius: 0.4rem;

  &__box-changes-enabled {
    padding: 1.5rem 0.5rem;
    background-color: $alert-background-blue;
    border: 0.01rem solid $alert-border-blue;
  }

  &__box-changes-disabled {
    padding: 1.5rem 0.5rem;
    background-color: $alert-background-grey;
    border: 0.01rem solid $alert-border-grey;
  }

  &__box-no-changes {
    padding: 1.5rem 0.5rem;
    background-color: $alert-background-grey;
    border: 0.01rem solid $alert-border-grey;
  }

  &__button {
    display: flex;
    align-items: center;
  }

  &__info {
    padding-bottom: 0.5rem;
  }

  &__last-updated-changes {
    padding-top: 0.5rem;
    color: $alert-subtext-blue;
  }

  &__last-updated-no-changes {
    padding-top: 0.5rem;
    color: $grey-text-light;
  }

  &__left-side-box-changes-enabled {
    color: $alert-text-blue;
  }

  &__left-side-box-no-changes,
  &__left-side-box-changes-disabled {
    color: $grey-text;
  }

  &__right-side-box {
    display: flex;
    justify-content: flex-end;
  }

  &__subinfo {
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }
}
