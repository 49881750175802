@import "styles/variables";

.event-bar {
  padding: 0;
  list-style: none;
  margin: 0;
  display: table;
  flex-direction: column;
  width: 99%; // so that the hightlight (light color) of the item in menu does not span to the edge of the bar
  max-width: 30rem;
  height: 100%;
  background-color: $grey-text;

  @media screen and (max-width: 768px) {
    max-width: 40rem;
    width: 100%;
  }

  &__end {
    display: flex;
    justify-content: center;
  }

  .entry {
    position: relative;
    width: 100%;
    [class^="icon-"] {
      margin-right: 0.625rem;
    }

    &__chevron {
      width: 5rem !important;
      cursor: pointer !important;
      padding: 1rem 0 0 0 !important;
    }

    &__label {
      display: inline-block;
      color: $ncim-white;
      padding: 0.75rem 2rem;
      border-radius: 0;
      background-color: $grey-text;
      border-left: 0;
      border-right: 0;
      border-color: $grey-text;
      width: 100%;
      cursor: pointer;
    }

    &__label:hover,
    &__label:active,
    &__label:focus {
      color: $grey-text;
      font-weight: bold;
      background: $ncim-white;
    }

    &__label-highlighted {
      color: $grey-text;
      font-weight: bold;
      background: $ncim-white;
    }

    &__top-row {
      display: flex;
    }
  }

  .items {
    display: inline-block;
    width: 100%;
    color: $ncim-white;
    padding: 0.75rem 2rem;
    margin: 0.25rem 0;
    cursor: pointer;
    border-radius: 0;
    background-color: $grey-text;
    border-left: 0;
    border-right: 0;
    border-color: $grey-text;
    [class^="icon-"] {
      margin-right: 0.625rem;
    }
  }

  .items:hover,
  .items:active,
  .items:focus {
    color: $grey-text;
    font-weight: bold;
    background: $ncim-white;
  }

  .items-disabled {
    color: $grey-text-light;
  }

  .items-highlighted {
    color: $grey-text;
    font-weight: bold;
    background: $ncim-white;
  }

  .items-indented {
    display: inline-block;
    width: 100%;
    padding: 0.75rem 2rem 0.75rem 4rem;
    cursor: pointer;
  }
}
