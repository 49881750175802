@import "./variables";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: $background;
}

.content {
  min-height: calc(100vh - 7rem);
  padding: 0 1rem;
}

.btn-cycling {
  background-color: $ncim-blue;
  color: $ncim-white;
  border: $ncim-blue;
  [class^="icon-"] {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}

.btn-cycling:hover {
  background-color: $ncim-blue-hover;
  color: $ncim-white;
  border-color: $ncim-blue-hover-border;
}

.btn-cycling:focus {
  background-color: $ncim-blue-hover !important;
  color: $ncim-white;
  border-color: $ncim-blue-hover-border !important;
  box-shadow: 0 0 0.25rem 0.25rem $ncim-blue-focus-border-shadow !important;
}

.btn-cycling:active {
  background-color: $ncim-blue-active !important;
  color: $ncim-white;
  border-color: $ncim-blue-active-border !important;
  box-shadow: none !important;
}

.btn-cycling:disabled {
  background-color: $ncim-blue-hover;
  color: $ncim-white;
  border-color: $ncim-blue-hover-border;
  opacity: 0.5;
}

.btn-cycling-secondary {
  background-color: $ncim-white;
  color: $grey-text;
  border-color: $grey-border;
}

.btn-cycling-secondary:hover {
  background-color: $grey-hover !important;
  color: $grey-text;
  border-color: $grey-hover-border !important;
}

.btn-cycling-secondary:focus {
  background-color: $grey-hover !important;
  color: $grey-text;
  border-color: $grey-hover-border !important;
  box-shadow: 0 0 0.25rem 0.25rem $grey-focus-border-shadow !important;
}

.btn-cycling-secondary:active {
  background-color: $grey-active !important;
  color: $grey-text !important;
  border-color: $grey-active-border !important;
  box-shadow: none !important;
}

.btn-cycling-secondary:disabled {
  background-color: $ncim-white;
  color: $grey-text;
  border-color: $grey-border;
  opacity: 0.75;

  &:hover {
    background-color: $ncim-white !important;
    color: $grey-text !important;
    border-color: $grey-border !important;
    opacity: 0.75 !important;
  }
  &:focus {
    background-color: $ncim-white !important;
    color: $grey-text !important;
    border-color: $grey-border !important;
    opacity: 0.75 !important;
  }
  &:active {
    background-color: $ncim-white !important;
    color: $grey-text !important;
    border-color: $grey-border !important;
    opacity: 0.75 !important;
  }
}

// EventBar items don't need the border. If not removed, the highligthed race and chevron will have different heights
.list-group-item {
  border: none;
}

.modal-body {
  color: $grey-text;
  padding: 1rem 2rem;
}

.modal-footer {
  border-top: none;
}

.modal-header {
  color: $grey-text;
  padding-left: 2rem;
  border-bottom: none;
}
