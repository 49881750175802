@import "styles/variables";

.loading-spinner {
  &__holder {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    color: $grey-text;
  }

  &__content h2 {
    padding-left: 1rem;
    color: $grey-text;
  }
}
