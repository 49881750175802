@import "styles/variables";

.create-new-event {
  &__header {
    font-size: 1.75rem;
    font-weight: 100;
    display: flex;
    justify-content: center;
    padding: 3.125rem 0;
  }

  &__btn-outline-create-event {
    font-size: 1.125rem;
    font-weight: 100;
    background-color: $ncim-white;
    color: $ncim-blue;
    border-color: $ncim-blue;
    width: 16.875rem;
    padding: 2rem 0;
  }

  &__btn-outline-create-event:hover {
    background-color: $ncim-blue-hover;
    color: $ncim-white;
    border-color: $ncim-blue-hover-border;
  }

  &__btn-outline-create-event:focus {
    background-color: $ncim-blue-hover !important;
    color: $ncim-white;
    border-color: $ncim-blue-hover-border !important;
    box-shadow: 0 0 0.25rem 0.25rem $ncim-blue-focus-border-shadow !important;
  }

  &__btn-outline-create-event:active {
    background-color: $ncim-blue-active !important;
    color: $ncim-white;
    border-color: $ncim-blue-active-border !important;
  }

  &__row {
    padding: 1.5rem 0 0 0;
  }
}

.tooltip [class$="inner"] {
  background-color: $danger-color;
}
