@import "styles/variables";

.results {
  &__bib-check {
    color: grey;
  }

  &__bib-container {
    outline: 0.125rem solid $grey-border;
    background-color: $ncim-white;
    color: $grey-text;
  }

  &__bib-header {
    margin-top: 1.25rem;
    font-size: 1rem;
    font-weight: 600;
  }

  &__button {
    margin: 0.625rem;
  }

  &__delete-legend {
    white-space: nowrap;
  }

  &__info {
    font-weight: 300;
  }

  &__race-name {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold !important;
    color: $grey-text;
  }

  &__table {
    color: $grey-text;
  }

  &__tb-bib-input:focus {
    border-color: $ncim-blue-hover-border !important;
    box-shadow: 0 0 0.25rem 0.25rem $ncim-blue-focus-border-shadow !important;
  }

  &__tb-btn {
    // Make button rounded
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    border-radius: 0.875rem;
    text-align: center;
    font-size: 1.5rem;
    line-height: 0;
    margin-right: 0.35rem;
    padding-bottom: 0.3rem;
  }

  &__tb-btn-sub {
    // Increase font size for "-" button,
    // prevent it from being too thick and tall
    font-size: 2.5rem;
    font-weight: 100;
    padding-bottom: 0.5rem;
  }

  &__tb-invalid {
    color: $invalid-color;
  }

  // Rider results table stylings
  &__th-bib {
    width: 15%;
    min-width: 5rem;
  }

  &__th-dns-dnf {
    width: 15%;
  }

  &__th-finish-order {
    width: 10%;
  }

  &__th-name {
    width: 30%;
  }

  &__th-laps {
    width: 30%;
  }
}
