@import "styles/variables";

.event-tile {
  width: 18.75rem;
  height: 25rem;
  background-color: $ncim-white;
  color: $grey-text;
  margin: 0.625rem;
  float: left;
  border-radius: 1.25rem;
  overflow: hidden;
  text-align: center;
  cursor: pointer;

  &__date {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.625rem;
  }

  &__image {
    height: 9.25rem;
    object-fit: cover;
    width: 100%;
    z-index: 0;
  }

  &__locked {
    float: right;
  }

  &__locked-icon {
    margin-right: 1rem;
  }

  &__races {
    font-weight: 100;
    padding: 0 0.625rem 0 0.625rem;
    font-size: 1rem;
    margin-top: 0.9rem;
  }

  &__status {
    background-color: $danger-color;
    width: 18.75rem;
    height: 2rem;
    margin-top: -2rem;
    position: absolute;
    padding: 0.3rem 0;
    font-weight: bold;
    color: $ncim-white;
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 100;
    padding: 0 0.625rem;
    margin-top: 0.9rem;
  }
}
.deleteEventDiv {
  align-self: center;
  float: right;
  position: relative;
  padding-bottom: 50%;
}
