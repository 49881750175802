@import "styles/variables";

.editRace-details {
  &__input-boxes {
    height: 1.85rem;
  }

  &__input-container {
    border: 0.01rem solid $grey-border;
    border-radius: 0.4rem;
    padding: 1rem;
    align-items: center;
  }

  &__input-description {
    font-weight: bold;
    margin: 0;
  }

  &__input-warning {
    margin-left: 1rem;
    margin-top: 1rem;
  }
}
