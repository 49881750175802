@import "styles/variables";

.loading {
  position: fixed;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: $background;
  z-index: 99999;

  &__image {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -3.75rem 0 0 -3.75rem;
  }
}
