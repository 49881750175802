@import "styles/variables";

.footer {
  background: $ncim-white;
  width: 100%;
  text-align: center;
  padding: 0.625rem 0;
  margin: 0;
  color: $grey-text;

  &__about {
    text-align: right;
    cursor: pointer;
  }
}
