@import "styles/variables";

.event-details {
  &__datetime {
    display: inline-block;
    width: 12.5rem;
  }

  &__fields {
    margin-bottom: 0.625rem;
    color: $grey-text;
  }
}
