@import "styles/variables";

.event {
  padding: 1.25rem 0;

  &__link {
    color: $ncim-blue;
    cursor: pointer;
  }
}
