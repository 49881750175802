@import "styles/variables";

.race-overview {
  margin: 0;

  &__cat-row {
    font-size: 1.1rem;
    font-weight: 600;
    padding-bottom: 0.35rem;
  }

  &__export {
    text-align: right;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &__lower-row {
    padding-bottom: 1rem;
    align-items: center;
  }

  &__race-header {
    padding: 1rem 0 0.75rem 0;
    border-bottom: 0.01rem solid $grey-border;
    @media screen and (max-width: 768px) {
      padding: 1rem;
    }
  }

  &__race-name-text {
    font-size: 1.5rem;
    font-weight: 600;
    color: $grey-text;
    word-wrap: normal;
  }

  &__race-type-name {
    color: $grey-text;
  }

  &__results-icon-full {
    padding-right: 0.5rem;
    color: $ncim-blue;
  }

  &__results-icon-part {
    padding-right: 0.5rem;
    color: $ncim-blue;
  }

  &__results-icon-none {
    margin-right: 0.5rem;
    color: $grey-text;
  }

  &__table-div {
    padding-top: 0.75rem;
  }

  &__table-header-1 {
    width: 60%;
  }

  &__table-header-2 {
    width: 40%;
  }

  &__table-row {
    color: $grey-text;
    vertical-align: middle !important;
  }

  &__table-whole-row {
    color: $grey-text;
    vertical-align: middle !important;
    cursor: pointer;
    background-color: $ncim-white;
    border: 0.01rem solid $grey-border;
  }

  &__table-whole-row:hover,
  &__table-whole-row:active {
    background-color: $grey-hover;
  }

  &__upper-row {
    padding-bottom: 1rem;
    align-items: center;
  }
}
