$alert-background-blue: #d4ecf1;
$alert-background-grey: #ebebeb;
$alert-border-blue: #c7e7eb;
$alert-border-grey: #d1d2d6;
$alert-subtext-blue: #3c7680;
$alert-text-blue: #0c5460;
$background: #fafafa;
$bagde-grey: #e6e6e6;
$box-shadow-color: rgb(218, 218, 218);
$danger-color: rgba(155, 0, 0, 0.8);
$grey-active: #e6e6e6;
$grey-active-border: #939393;
$grey-border: #d5d5d5;
$grey-focus-border-shadow: #0a7ea333;
$grey-hover: #f0f0f0;
$grey-hover-border: #b5b5b5;
$grey-light: #d8d8d8;
$grey-text: #4f4f4f;
$grey-text-light: #767676;
$invalid-color: #dc3545;
$ncim-blue: #007ea3;
$ncim-blue-active: #00587d;
$ncim-blue-active-border: #004b70;
$ncim-blue-focus-border-shadow: #0a7ea333;
$ncim-blue-hover: #00658a;
$ncim-blue-hover-border: #00587d;
$ncim-blue-link: #007496;
$ncim-logo-blue: #0da7d6;
$ncim-white: #ffffff;

$danger-symbol: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
